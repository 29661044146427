import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import Img from "../Assets/icons/VELOXLogo.png";
import "../Styles/UserManagement.css";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const UserManagement = () => {
    const [users, setUsers] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    const fetchUsers = () => {
        const email = localStorage.getItem('userEmail');
        Axios.post(`${process.env.REACT_APP_BACKEND_URL}/get_users`, { email }).then((response) => {
            if (response.data.success) {
                setUsers(response.data.data);
            } else {
                toast.error("Failed to get all users.", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        });
    };

    useEffect(() => {
        fetchUsers();
    }, []);

    const handleSelectUser = (userEmail) => {
        setSelectedUsers((prev) => {
            if (prev.includes(userEmail)) {
                return prev.filter((email) => email !== userEmail);
            } else {
                return [...prev, userEmail];
            }
        });
    };

    const handleSelectAll = () => {
        if (selectAll) {
            setSelectedUsers([]);
        } else {
            setSelectedUsers(users.map(user => user.email));
        }
        setSelectAll(!selectAll);
    };

    const handleDeleteUsers = () => {
        Axios.post(`${process.env.REACT_APP_BACKEND_URL}/delete_users`, { emails: selectedUsers }).then((response) => {
            if (response.data.success) {
                toast.success(`${response.data.msg}`, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
            fetchUsers();
            setSelectedUsers([]);
            setOpenDialog(false);;
        });
    };

    const handleBack = () => {
        console.log("Back button clicked!");
        console.log("Current path:", location.pathname);
        // Dismiss any active toasts before navigating
        toast.dismiss();
        // Check if we can go back in history
        if (window.history.state && window.history.state.idx > 0) {
            navigate(-1);  // Go back one step in history
            console.log("Navigating back one step.");
        } else {
            // If no history, navigate to the dashboard without adding an entry
            navigate('/dashboard', { replace: true });
            console.log("No history to go back, redirecting to dashboard.");
        }
    };

    const handleLogout = () => {
        localStorage.removeItem('@user');
        localStorage.removeItem('userEmail');
        navigate('/', { replace: true });
    };

    return (
        <div>
            <div className='logo-VELOX'>
                <img src={Img} alt="VELOX Life Safety Inc." className="logo" />
                <button className='back-botton' onClick={handleBack}>BACK</button>
                <button className='signout-botton' onClick={handleLogout}>SIGN OUT</button>
            </div>
            <hr className='login-breakline' />
            <div className="all-users-card">
                <h1 className='Title-alluser'>All Users</h1>
                <div className="button-row">
                    <button onClick={fetchUsers} className="refresh-button">Refresh</button>
                    <button
                        onClick={() => setOpenDialog(true)}
                        className="delete-button"
                        disabled={selectedUsers.length === 0}
                    >
                        Delete
                    </button>
                </div>
                <div className="table-container">
                    <table className="styled-table">
                        <thead>
                            <tr>
                                <th>
                                    <input
                                        type="checkbox"
                                        checked={selectAll}
                                        onChange={handleSelectAll}
                                    />
                                </th>
                                <th>Order</th>
                                <th>First Name</th>
                                <th>Last Name</th>
                                <th>Email</th>
                                <th>YubiKey Serial Number</th>
                            </tr>
                        </thead>
                        <tbody>
                            {users.map((user, index) => (
                                <tr key={user.email}>
                                    <td>
                                        <input
                                            type="checkbox"
                                            checked={selectedUsers.includes(user.email)}
                                            onChange={() => handleSelectUser(user.email)}
                                        />
                                    </td>
                                    <td>{index + 1}</td>
                                    <td>{user.first_name}</td>
                                    <td>{user.last_name}</td>
                                    <td>{user.email}</td>
                                    <td>{user.yubikey_serial_number}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                <Dialog
                    open={openDialog}
                    onClose={() => setOpenDialog(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Delete Users"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Do you want to delete these users?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleDeleteUsers} color="primary">
                            Yes
                        </Button>
                        <Button onClick={() => setOpenDialog(false)} color="secondary" autoFocus>
                            No
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
            <ToastContainer />
        </div>
    );
};

export default UserManagement;
