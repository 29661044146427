import React from 'react';
import "../Styles/CreateUser.css";
import * as yup from "yup";
import { useNavigate, useLocation } from 'react-router-dom';
import { ErrorMessage, Formik, Form, Field } from "formik";
import Axios from "axios";
import { toast, ToastContainer, toast as toastHelper } from 'react-toastify';
import Img from "../Assets/icons/VELOXLogo.png";

const CreateUser = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleBack = () => {
    console.log("Back button clicked!");         
    console.log("Current path:", location.pathname);

    // Dismiss any active toasts before navigating
    toastHelper.dismiss();

    // Check if we can go back in history
    if (window.history.state && window.history.state.idx > 0) {
      navigate(-1);  // Go back one step in history
      console.log("Navigating back one step.");
    } else {
      // If no history, navigate to the dashboard without adding an entry
      navigate('/dashboard', { replace: true });
      console.log("No history to go back, redirecting to dashboard.");
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('@user');
    localStorage.removeItem('userEmail');
    toastHelper.dismiss();  // Ensure any active toasts are dismissed before logout
    navigate('/', { replace: true });  // Use replace to avoid adding history
  };

  const handleCreateUser = (values, { resetForm }) => {
    Axios.post(`${process.env.REACT_APP_BACKEND_URL}/create_user`, {
      firstname: values.firstname,
      lastname: values.lastname,
      email: values.email,
      password: values.password,
      yubikey: values.yubikeyserialnumber,
      adminEmail: localStorage.getItem('userEmail'),
    }).then((response) => {
      if (response && response.data && response.data.success) {
        toast.success(response.data.msg || "User created successfully!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        resetForm();
        // // Optionally navigate to the user management page or dashboard after user creation
        // navigate('/user-management', { replace: true });  // Use replace to avoid extra entry
      }
      else {
        toast.error("Failed to add user, please try again!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }).catch((error) => {
      toast.error("Failed to add user, please try again!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    });
  };

  const validationsCreateUser = yup.object().shape({
    firstname: yup
      .string()
      .matches(/^[A-Za-z]+$/, "First name should only contain alphabets")
      .required("First name is mandatory"),
    lastname: yup
      .string()
      .matches(/^[A-Za-z]+$/, "Last name should only contain alphabets")
      .required("Last name is mandatory"),
    email: yup
      .string()
      .email("Invalid email")
      .required("Email is mandatory"),
    password: yup
      .string()
      .min(8, "Password must be at least 8 characters")
      .matches(/[0-9]/, 'Password requires a number')
      .matches(/[a-z]/, 'Password requires a lowercase letter')
      .matches(/[A-Z]/, 'Password requires an uppercase letter')
      .matches(/[^\w]/, 'Password requires a symbol')
      .required("Password is mandatory"),
  });

  return (
    <div>
      <div className='logo-VELOX'>
        <img src={Img} alt="VELOX Life Safety Inc." className="logo" />
        <button className='back-botton' onClick={handleBack}>BACK</button>
        <button className='signout-botton' onClick={handleLogout}>SIGN OUT</button>
      </div>
      <hr className='login-breakline' />
      <div className='register-card'>
        <h1 className='register'>Create New User</h1>
        <Formik
          initialValues={{
            firstname: '',
            lastname: '',
            email: '',
            password: '',
            yubikeyserialnumber: '',
          }}
          onSubmit={handleCreateUser}
          validationSchema={validationsCreateUser}
        >
          <Form className="register-form">
            <div className="register-form-group">
              <Field name="firstname" type='text' className="register-form-firstname-field" placeholder="First name" />
              <ErrorMessage component="span" name="firstname" className="adduser-form-error" />
            </div>

            <div className="register-form-group">
              <Field name="lastname" type='text' className="register-form-lastname-field" placeholder="Last name" />
              <ErrorMessage component="span" name="lastname" className="adduser-form-error" />
            </div>

            <div className="register-form-group">
              <Field name="email" type='text' className="register-form-email-field" placeholder="Email" />
              <ErrorMessage component="span" name="email" className="adduser-form-error" />
            </div>

            <div className="register-form-group">
              <Field name="password" type='password' className="register-form-password-field" placeholder="Password" />
              <ErrorMessage component="span" name="password" className="form-error" />
            </div>

            <div className="register-form-group">
              <Field name="yubikeyserialnumber" type='text' className="register-form-yubikeyserialnumber-field" placeholder="YubiKey Serial Number" />
            </div>

            <button className="createuser-button" type="submit">
              Create User
            </button>
          </Form>
        </Formik>
      </div>
      <ToastContainer position='top-right' />
    </div>
  );
};

export default CreateUser;
