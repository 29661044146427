import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Img from "../Assets/icons/VELOXLogo.png";
import UserIcon from "../Assets/icons/User.svg";
import "../Styles/Dashboard.css";
import Axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';

const Dashboard = () => {
  const navigate = useNavigate();
  const [isAdmin, setIsAdmin] = useState(false);
  const [open, setOpen] = useState(false);
  const menuRef = useRef(null);
  const iconRef = useRef(null);

  useEffect(() => {
    const email = localStorage.getItem('userEmail');

    Axios.get('https://ipapi.co/json/').then((response) => {
      const ipAddress = response.data.ip;
      const ipLocation = {
        city: response.data.city,
        region: response.data.region,
        country: response.data.country_name,
        latitude: response.data.latitude,
        longitude: response.data.longitude
      };

      // Send IP address and location to the backend
      Axios.post(`${process.env.REACT_APP_BACKEND_URL}/store_ip_address`, {
        email: email,
        ipAddress: ipAddress,
        ipLocation: JSON.stringify(ipLocation) // Convert the location data to JSON
      }).then((response) => {
        // You can still log the result or handle errors without displaying a toast
        if (!response.data.success) {
          console.error("Failed to store IP information.");
        }
      }).catch((error) => {
        console.error("An error occurred while storing IP information.");
      });
    }).catch((error) => {
      console.error("Failed to fetch IP information.");
    });

    Axios.post(`${process.env.REACT_APP_BACKEND_URL}/get_user_role`, { email }).then((response) => {
      if (response.data.success) {
        setIsAdmin(!!response.data.is_admin);
      } else {
        toast.error("Failed to retrieve user role");
      }
    }).catch((error) => {
      toast.error("An error occurred while fetching user role.");
    });

    const loginSuccessMessage = localStorage.getItem('loginSuccessMessage');
    if (loginSuccessMessage) {
      toast.success(loginSuccessMessage, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      localStorage.removeItem('loginSuccessMessage');
    }
  }, []);

  const handleToggle = () => {
    setOpen(!open);
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target) && !iconRef.current.contains(event.target)) {
      setOpen(false);
    }
  };

  useEffect(() => {
    if (open) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [open]);

  const handleLogout = () => {
    localStorage.removeItem('@user');
    localStorage.removeItem('userEmail');
    navigate('/');
  };

  const createUser = () => {
    navigate('/create-user');
  };

  const showUsers = () => {
    navigate('/user-management');
  };

  const updatePassword = () => {
    navigate('/update_password');
  };

  return (
    <div className="dashboard">
      <div className='logo-VELOX'>
        <img src={Img} alt="VELOX Life Safety Inc." className="logo" />
        <img
          src={UserIcon}
          alt="User Profile"
          className="user-pic"
          onClick={handleToggle}
          ref={iconRef}
        />
      </div>
      <hr className='login-breakline' />
      {open && (
        <div className="sub-menu-wrap open-menu" ref={menuRef}>
          <div className="sub-menu">
            {isAdmin && (
              <>
                <a href="#" className="sub-menu-link" onClick={createUser}>
                  <p>Add User</p>
                </a>
                <a href="#" className="sub-menu-link" onClick={showUsers}>
                  <p>Show Users</p>
                </a>
              </>
            )}
            <a href="#" className="sub-menu-link" onClick={updatePassword}>
              <p>Change Password</p>
            </a>
            <a href="#" className="sub-menu-link" onClick={handleLogout}>
              <p>Logout</p>
            </a>
          </div>
        </div>
      )}
      <ToastContainer />
    </div>
  );
};

export default Dashboard;