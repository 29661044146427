import React, { useState, useRef, useEffect } from 'react';
import "../Styles/login.css";
import * as yup from "yup";
import { ErrorMessage, Formik, Form, Field } from "formik";
import Axios from "axios";
import Img from "../Assets/icons/VELOXLogo.png";
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

function Login({ logado: logged = false }) {
  const navigate = useNavigate();
  const [openYubikeyDialog, setOpenYubikeyDialog] = useState(false);  // Manage dialog state
  const [yubikeyCode, setYubikeyCode] = useState('');
  const yubikeyInputRef = useRef(null);
  const dialogContentRef = useRef(null);

  const handleLogin = (values) => {
    Axios.post(`${process.env.REACT_APP_BACKEND_URL}/login`, {
      email: values.email,
      password: values.password,
    }).then((response) => {
      if (response.data.success) {
        toast.success('Verified your credential, please verify your YubiKey!', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        localStorage.setItem('userEmail', values.email);
        setOpenYubikeyDialog(true);
      } else {
        toast.error(response.data.msg || 'Incorrect email or password', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }).catch((error) => {
      toast.error("Your Email and password didn't match!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    });
  };

  const handleYubikeyVerification = () => {
    const email = localStorage.getItem('userEmail');
    Axios.post(`${process.env.REACT_APP_BACKEND_URL}/login_verify_yubikey`, {
      email: email,
      yubikeyContent: yubikeyCode,
    }).then((response) => {
      if (response.data.success) {
        localStorage.setItem('loginSuccessMessage', response.data.msg);
        setOpenYubikeyDialog(false);
        localStorage.setItem('@user', "true");
        console.log(localStorage.getItem('@user'));
        console.log("Login success, will go to dashboard");
        navigate('/dashboard');
      } else {
        toast.error(`${response.data.msg}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        if (yubikeyInputRef.current) {
          yubikeyInputRef.current.focus();  // Set focus back to the input field if there's an error
        }
      }
      setYubikeyCode('');
    }).catch((error) => {
      console.error('Verification error:', error);
      setYubikeyCode('');
      if (yubikeyInputRef.current) {
        yubikeyInputRef.current.focus();  // Set focus back to the input field even if there's an error
      }
    });
  };

  useEffect(() => {
    const handleDialogClick = (event) => {
      if (dialogContentRef.current && yubikeyInputRef.current && !yubikeyInputRef.current.contains(event.target)) {
        yubikeyInputRef.current.focus();  // Refocus the input field when clicking outside of it
      }
    };

    if (openYubikeyDialog) {
      // Set focus when the dialog opens
      if (yubikeyInputRef.current) {
        yubikeyInputRef.current.focus();
      }

      // Add click event listener to the dialog content
      document.addEventListener('click', handleDialogClick);
    }

    // Cleanup event listener when the dialog is closed
    return () => {
      document.removeEventListener('click', handleDialogClick);
    };
  }, [openYubikeyDialog]);

  const validationsLogin = yup.object().shape({
    email: yup
      .string()
      .email("Invalid email")
      .required("Email is mandatory"),
    password: yup
      .string()
      .min(8, "Password must be at least 8 characters")
      .required("Password is mandatory"),
  });

  return (
    <div>
      <div className='logo-VELOX'>
        <img src={Img} alt="VELOX Life Safety Inc." className="logo" />
        <button className='login-back-botton' onClick={() => navigate(-1)}>BACK</button>
      </div>
      <hr className='login-breakline' />
      <div className='login-card'>
        <h1 className='login'>Login</h1>
        <Formik
          initialValues={{}}
          onSubmit={handleLogin}
          validationSchema={validationsLogin}
        >
          <Form className="login-form">
            <div className="login-form-group">
              <Field name="email" type='email' className="login-form-email-field" placeholder="Email" />
              <ErrorMessage component="span" name="email" className="form-error" />
            </div>

            <div className="login-form-group">
              <Field name="password" type='password' className="login-form-password-field" placeholder="Password" />
              <ErrorMessage component="span" name="password" className="form-error" />
            </div>

            <button className="verify-yubikey-button" type="submit">
              Verify Your YubiKey
            </button>
          </Form>
        </Formik>
      </div>

      <Dialog open={openYubikeyDialog} onClose={() => setOpenYubikeyDialog(false)}>
        <DialogTitle>YubiKey Verification</DialogTitle>
        <DialogContent ref={dialogContentRef}>
          <p>Please insert your YubiKey, touch it, and then verify.</p>
          <input
            type="text"
            ref={yubikeyInputRef}
            className="yubikeycode-field-hidden"
            placeholder="Enter YubiKey Code"
            value={yubikeyCode}
            onChange={(e) => setYubikeyCode(e.target.value)}
            autoFocus
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenYubikeyDialog(false)} color="secondary">Cancel</Button>
          <Button onClick={handleYubikeyVerification} color="primary" disabled={!(yubikeyCode && yubikeyCode.length >= 12)}>
            Verify
          </Button>
        </DialogActions>
      </Dialog>

      <ToastContainer />
    </div>
  );
}

export default Login;
