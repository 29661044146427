import React, { useState, useRef, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import Img from "../Assets/icons/VELOXLogo.png";
import "../Styles/login.css";
import "../Styles/UserManagement.css";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as yup from 'yup';

const UpdatePassword = () => {
  const [openYubikeyDialog, setOpenYubikeyDialog] = useState(false);
  const [yubikeyCode, setYubikeyCode] = useState('');
  const yubikeyInputRef = useRef(null);
  const dialogContentRef = useRef(null);
  const [passwordToChange, setPasswordToChange] = useState('');
  const navigate = useNavigate();
  const location = useLocation();

  const handlePasswordChange = (values) => {
    if (values.password !== values.confirmPassword) {
      toast.error("Passwords do not match", {
        position: "top-right",
        autoClose: 5000,
      });
      return;
    }
    setPasswordToChange(values.password);
    setOpenYubikeyDialog(true);  // Open YubiKey verification dialog
  };

  const handleYubikeyVerification = () => {
    const email = localStorage.getItem('userEmail');
    Axios.post(`${process.env.REACT_APP_BACKEND_URL}/change_password`, {
      email: email,
      password: passwordToChange,
      yubikeyContent: yubikeyCode,
    }).then((response) => {
      if (response.data.success) {
        toast.success("Password changed successfully");
        setYubikeyCode('');
        setOpenYubikeyDialog(false);
        navigate('/dashboard', { replace: true });
      } else {
        toast.error(response.data.msg);
        if (yubikeyInputRef.current) {
            yubikeyInputRef.current.focus();
          }
      }
      setYubikeyCode('');
    }).catch((error) => {
      toast.error("YubiKey verification failed");
      setYubikeyCode('');
      if (yubikeyInputRef.current) {
        yubikeyInputRef.current.focus();
      }
    });
  };

  const handleBack = () => {
    console.log("Back button clicked!");         
    console.log("Current path:", location.pathname);
    // Dismiss any active toasts before navigating
    toast.dismiss();
    // Check if we can go back in history
    if (window.history.state && window.history.state.idx > 0) {
      navigate(-1);  // Go back one step in history
      console.log("Navigating back one step.");
    } else {
      // If no history, navigate to the dashboard without adding an entry
      navigate('/dashboard', { replace: true });
      console.log("No history to go back, redirecting to dashboard.");
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('@user');
    localStorage.removeItem('userEmail');
    navigate('/', { replace: true });
  };

  useEffect(() => {
    const handleDialogClick = (event) => {
      if (dialogContentRef.current && yubikeyInputRef.current && !yubikeyInputRef.current.contains(event.target)) {
        yubikeyInputRef.current.focus();  // Refocus the input field when clicking outside of it
      }
    };

    if (openYubikeyDialog) {
      // Set focus when the dialog opens
      if (yubikeyInputRef.current) {
        yubikeyInputRef.current.focus();
      }

      // Add click event listener to the dialog content
      document.addEventListener('click', handleDialogClick);
    }

    // Cleanup event listener when the dialog is closed
    return () => {
      document.removeEventListener('click', handleDialogClick);
    };
  }, [openYubikeyDialog]);

  const validationSchema = yup.object().shape({
    password: yup
      .string()
      .min(8, "Password must be at least 8 characters")
      .required("Password is mandatory"),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref('password'), null], 'Passwords must match')
      .required('Confirm Password is mandatory'),
  });

  return (
    <div>
      {/* Logo and Navigation */}
      <div className='logo-VELOX'>
        <img src={Img} alt="VELOX Life Safety Inc." className="logo" />
        <button className='back-botton' onClick={handleBack}>BACK</button>
        <button className='signout-botton' onClick={handleLogout}>SIGN OUT</button>
      </div>
      <hr className='login-breakline' />

      <div className="login-card">
        <h1 className='login'>Change Password</h1>
        <Formik
          initialValues={{
            password: '',
            confirmPassword: ''
          }}
          onSubmit={handlePasswordChange}
          validationSchema={validationSchema}
        >
          {() => (
            <Form className="login-form">
              <div className="login-form-group">
                <Field
                  name="password"
                  type="password"
                  className="login-form-password-field"
                  placeholder="Enter new password"
                />
                <ErrorMessage name="password" component="span" className="form-error" />
              </div>

              <div className="login-form-group">
                <Field
                  name="confirmPassword"
                  type="password"
                  className="login-form-password-field"
                  placeholder="Confirm new password"
                />
                <ErrorMessage name="confirmPassword" component="span" className="form-error" />
              </div>

              <button className="verify-yubikey-button" type="submit">
              Verify Your YubiKey
              </button>
            </Form>
          )}
        </Formik>
      </div>

      {/* YubiKey Verification Dialog */}
      <Dialog open={openYubikeyDialog} onClose={() => setOpenYubikeyDialog(false)}>
        <DialogTitle>YubiKey Verification</DialogTitle>
        <DialogContent ref={dialogContentRef}>
          <p>Please insert your YubiKey, touch it, and then verify.</p>
          <input
            type="text"
            ref={yubikeyInputRef}
            className="yubikeycode-field-hidden"
            placeholder="Enter YubiKey Code"
            value={yubikeyCode}
            onChange={(e) => setYubikeyCode(e.target.value)}
            autoFocus
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenYubikeyDialog(false)} color="secondary">Cancel</Button>
          <Button onClick={handleYubikeyVerification} color="primary" disabled={!(yubikeyCode && yubikeyCode.length >= 12)}>
            Verify
          </Button>
        </DialogActions>
      </Dialog>

      <ToastContainer />
    </div>
  );
};

export default UpdatePassword;
