import React from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import LandingPage from "../Pages/LandingPage";
import Login from "../Pages/Login";
import Register from "../Pages/Register";
import Dashboard from "../Pages/Dashboard";
import CreateUser from "../Pages/CreateUser";
import UserManagement from "../Pages/UserManagement";
import UpdatePassword from "../Pages/UpdatePassword";

const isAuthenticated = () => {
  return localStorage.getItem('@user') === "true";
};

const Rotas = () => {

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/dashboard" element={isAuthenticated() ? <Dashboard /> : <Navigate to="/" />} />
        <Route path="/create-user" element={isAuthenticated() ? <CreateUser /> : <Navigate to="/" />} />
        <Route path="/user-management" element={isAuthenticated() ? <UserManagement /> : <Navigate to="/" />} />
        <Route path="/update_password" element={isAuthenticated() ? <UpdatePassword /> : <Navigate to="/" />} />
        <Route path="*" element={<Navigate to={isAuthenticated() ? "/hello" : "/"} />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Rotas;
